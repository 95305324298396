import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'

interface StyledProps {
  styles: StyledTextRedProps
}
interface StyledTextRedProps {
  isred: boolean
}

export const StyledTextRed = styled.div<StyledProps>`
  color: ${(props) => (props.styles.isred ? 'red' : 'black')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledRightOutlined = styled(RightOutlined)<StyledProps>`
  color: ${(props) =>
    props.styles.isred ? 'lightgray !important' : 'inherit'};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  margin-top: 0px;
`

export const StyledTextClick = styled.span`
  cursor: pointer;

  :hover {
    font-weight: 700;
  }
`
