import moment from 'moment'

import { reportMonthColumns } from '../../components/ReportMonthColumns'
import { ReportMonthsResume } from '../../components/ReportMonthsResume'
import { TableGridProps } from '../../components/Storybook/TableGrid'
import { TileRowsProps } from '../../components/Storybook/TileRows'

import {
  QueryReportDetailSales,
  QueryReportDetailSummary
} from '../../types/Reports/ReportMonths'
import {
  capitalizeFirstsLettersOfString,
  getDateFromDigits,
  getMonthYearFromSixDigits,
  getStartAndEndDayOfMonth
} from '../../utils/general'
import { getParameter } from '../../utils/queryString'
import { getsummaryLabel } from '../FilterSalesReportContainer/utils'

interface TransformQueryToDataTableGridProps {
  tileRow: TileRowsProps
  tableGrid: TableGridProps
}

interface ReturnDiffDaysParamsDate {
  from?: string
  to?: string
  days: number
}
const getDiffDaysParamsDate = (): ReturnDiffDaysParamsDate => {
  const from = getParameter('from')
  const to = getParameter('to')
  if (!from || !to) return { from, to, days: 0 }
  const days = moment(to).diff(from, 'days')
  return { from, to, days }
}

const fillReportsMonthWithEmpty = (
  dataTable: QueryReportDetailSummary[]
): QueryReportDetailSummary[] => {
  const { to, days } = getDiffDaysParamsDate()
  const res: QueryReportDetailSummary[] = []
  for (let index = 0; index <= days; index++) {
    const date = moment(to, 'YYYYMMDD')
      .subtract(index, 'days')
      .format('YYYYMMDD')

    const existDate = dataTable.find(({ item }) => item === date)
    const emptyReportMonth = {
      item: date,
      view: 'month',
      totalOperations: 0,
      totalAmount: 0,
      storeId: ''
    }
    res.push(existDate || emptyReportMonth)
  }
  return res
}

const getCorrespondingDataTable = (
  detailSummary: QueryReportDetailSummary[],
  view: string,
  storeId: string,
  isFilterMenu = false
): QueryReportDetailSummary[] => {
  const dataTable = detailSummary.map((item) => ({
    ...item,
    view,
    storeId
  }))
  if (view === 'months') {
    return dataTable
  }

  const fullMonth = fillReportsMonthWithEmpty(dataTable)

  const reultMonth = isFilterMenu ? dataTable : fullMonth

  return reultMonth
}

const transformQueryToDataTableGrid = (
  queryData: QueryReportDetailSales,
  view: string,
  storeId: string,
  isFilterMenu = false
): TableGridProps => {
  const { detailSummary } = queryData
  const dataTable: any = getCorrespondingDataTable(
    detailSummary,
    view,
    storeId,
    isFilterMenu
  )
  const columns = reportMonthColumns
  const tableGrid = {
    data: dataTable,
    columns,
    isHeader: false
  }
  return tableGrid
}

export const isTheStartAndEndOfOneMonth = (
  from = '20220101',
  to = '20220130'
): boolean => {
  const startOfThisMonth = getDateFromDigits(from)
  const endOfThisMonth = getDateFromDigits(to)

  const { start, end } = getStartAndEndDayOfMonth(startOfThisMonth)

  const isTheStartOfThisMonth = start === startOfThisMonth
  const isTheEndOfThisMonth = end === endOfThisMonth

  return isTheStartOfThisMonth && isTheEndOfThisMonth
}

export const getFromTitleDetail = (
  from = '20220101',
  to = '20220130'
): string => {
  const isASpecificMonth = isTheStartAndEndOfOneMonth(from, to)

  if (isASpecificMonth) {
    return getMonthYearFromSixDigits(from)
  }
  const fromDayAndMonth = moment(from).format("DD/MM/'YY")
  const toDayAndMonth = moment(to).format("DD/MM/'YY")
  return fromDayAndMonth + ' - ' + toDayAndMonth
}

export const transformQueryReportToDataComponents = (
  queryData: {
    generalDetail: QueryReportDetailSales
  },
  view: string,
  storeId: string,
  isFilterMenu = false
): TransformQueryToDataTableGridProps => {
  const { generalDetail } = queryData
  const { summarySales } = generalDetail
  const { fromTitleDetail: from, toTitleDetail: to } = summarySales

  const fromTitleDetailRange = getFromTitleDetail(from, to)
  const fromTitleDetail = getsummaryLabel(
    capitalizeFirstsLettersOfString(fromTitleDetailRange)
  )

  const tileRow = ReportMonthsResume(
    { ...summarySales, fromTitleDetail },
    view,
    from,
    to,
    storeId
  )
  const tableGrid = transformQueryToDataTableGrid(
    generalDetail,
    view,
    storeId,
    isFilterMenu
  )

  return { tileRow, tableGrid }
}

/* export const jsonToCsv = (items: any[]): string => {
  const replacer = (key, value: string) => (value === null ? '' : value)
  const header = Object.keys(items[0])
  const csv = [
    header.join(','), // header row first
    ...items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    )
  ].join('\r\n')
  return csv
} */

/* export const getDateFormatReportFile = (
  item = '202202',
  view: 'months' | 'month'
): string => {
  if (view === 'month') {
    const day = getDateFromDigits(item)
    return capitalizeFirstsLettersOfString(moment(day).format('DD MMMM YYYY'))
  }

  return getMonthYearFromSixDigits(item)
} */
