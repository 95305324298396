import styled from 'styled-components'

export const StyledConatnerReportByMonthsPDF = styled.div`
  font-size: 9px;

  padding: 12px;
`

export const StyledContainerTable = styled.div`
  padding: 12px;
`

export const StyledContainerTableDetail = styled.div`
  padding: 40px 12px;
`

export const StyledTHSub = styled.th`
  padding: 5px 2px;
  border: 1px solid black;
  background-color: #d9e1f2;
`

export const StyledTHDetail = styled.th`
  padding: 5px 2px;
  border: 1px solid black;
  font-weight: normal;
`
