import React from 'react'

import {
  StyledConatnerReportByMonthsPDF,
  StyledContainerTableDetail,
  StyledTHDetail,
  StyledTHSub
} from './styledComponent'

import {
  ReportDownloadFileType,
  detailsummary
} from '../../types/Reports/ReportDownloadFileByMonths'

import {
  LandscapeOrientation,
  PDFHeaderContainer,
  TableHeadersTR
} from '../ReportComponentsPDF'
import {
  convertNumberToMoney,
  getMonthYearFromSixDigits
} from '../../utils/general'

const TableSubHeadersTR: React.FC = () => (
  <tr>
    <StyledTHSub>Mes</StyledTHSub>
    <StyledTHSub>Año</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>

    <StyledTHSub>Operaciones</StyledTHSub>
    <StyledTHSub>Monto</StyledTHSub>
  </tr>
)

export const TableRowDetailsSummary: React.FC<detailsummary> = ({
  date,
  cardCounter,
  cardAmount,
  cashCounter,
  cashAmount,
  loyaltyCounter,
  loyaltyAmount,
  mixedLoyaltyCardCounter,
  mixedLoyaltyCardAmount,
  mixedLoyaltyCashCardCounter,
  mixedLoyaltyCashCardAmount,
  mixedLoyaltyCashCounter,
  mixedLoyaltyCashAmount,
  totalCounter,
  totalAmount
}) => {
  const [month, year] = getMonthYearFromSixDigits(date).split(' ')

  return (
    <tr>
      <StyledTHSub>{month}</StyledTHSub>
      <StyledTHSub>{year}</StyledTHSub>

      <StyledTHDetail>{cardCounter}</StyledTHDetail>
      <StyledTHDetail>{convertNumberToMoney(cardAmount)}</StyledTHDetail>

      <StyledTHDetail>{cashCounter}</StyledTHDetail>
      <StyledTHDetail>{convertNumberToMoney(cashAmount)}</StyledTHDetail>

      <StyledTHDetail>{loyaltyCounter}</StyledTHDetail>
      <StyledTHDetail>{convertNumberToMoney(loyaltyAmount)}</StyledTHDetail>

      <StyledTHDetail>{mixedLoyaltyCardCounter}</StyledTHDetail>
      <StyledTHDetail>
        {convertNumberToMoney(mixedLoyaltyCardAmount)}
      </StyledTHDetail>

      <StyledTHDetail>{mixedLoyaltyCashCounter}</StyledTHDetail>
      <StyledTHDetail>
        {convertNumberToMoney(mixedLoyaltyCashAmount)}
      </StyledTHDetail>

      <StyledTHDetail>{mixedLoyaltyCashCardCounter}</StyledTHDetail>
      <StyledTHDetail>
        {convertNumberToMoney(mixedLoyaltyCashCardAmount)}
      </StyledTHDetail>

      <StyledTHDetail>{totalCounter}</StyledTHDetail>
      <StyledTHDetail>{convertNumberToMoney(totalAmount)}</StyledTHDetail>
    </tr>
  )
}
interface propsType {
  storeInformation: {
    storeID: string | undefined
    storeName: string
    partnerName: string
  }
  queryData: {
    TotalOperationsReport: ReportDownloadFileType
  } | null
}

export const ReportByMonthsPDF = React.forwardRef(
  (
    props: propsType,
    ref?:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { queryData } = props

    if (!queryData) return null
    const { TotalOperationsReport: data } = queryData

    const labelHeader: string[] = [
      'PERIODO',
      'TARJETA',
      'EFECTIVO',
      'CLUB MODELORAMA',
      'PUNTOS + TARJETA',
      'PUNTOS + EFECTIVO',
      'TARJETA + EFECTIVO',
      'TOTAL'
    ]

    return (
      <StyledConatnerReportByMonthsPDF ref={ref}>
        <LandscapeOrientation />
        <div>
          <PDFHeaderContainer
            storeID={props.storeInformation.storeID}
            storeName={props.storeInformation.storeName}
            partnerName={props.storeInformation.partnerName}
            title="REPORTE DE TOTALES MENSUALES"
          />
          <StyledContainerTableDetail>
            <table>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <TableHeadersTR labelHeader={labelHeader} colSpan={2} />
                <TableSubHeadersTR />
                {data.detailsummary.map((dataRow, index) => (
                  <TableRowDetailsSummary {...dataRow} key={index} />
                ))}
              </tbody>
            </table>
          </StyledContainerTableDetail>
        </div>
      </StyledConatnerReportByMonthsPDF>
    )
  }
)
