import React from 'react'
import { navigate } from 'gatsby'
import { Col, Row } from 'antd'

import {
  StyledRightOutlined,
  StyledTextClick,
  StyledTextRed
} from './styledComponents'
import { TGColumnProp } from '../Storybook/TableGrid'

import {
  convertNumberToMoney,
  getMonthDayFromEightDigits,
  getMonthYearFromSixDigits,
  getStartAndEndMonthIfActualMonth
} from '../../utils/general'
import { QueryReportDetailSummary } from '../../types/Reports/ReportMonths'
import moment from 'moment'
import Amount from '../Amount'
import { getParameter } from '../../utils/queryString'
export const renderTotal = (
  _objt: string,
  row: QueryReportDetailSummary
): React.ReactElement => {
  const { totalAmount, totalOperations, item, view, storeId } = row
  const addrUserId = getParameter('userId')
    ? `&userId=${getParameter('userId')}`
    : ''

  const onClickMonths = () => {
    const { from, to } = getStartAndEndMonthIfActualMonth(item)
    navigate(
      `/reports/sales/details-by-month-days?from=${from}&to=${to}&storeId=${storeId}${addrUserId}`
    )
  }

  const onClickMonth = () => {
    const dateFormat = moment(item).format('YYYY-MM-DD')
    const addr = `/reports/sales/details-by-day-tickets?from=${dateFormat}&storeId=${storeId}${addrUserId}`

    navigate(addr)
  }

  const onClick = () => {
    if (totalOperations === 0) return
    switch (view) {
      case 'month':
        onClickMonth()
        break

      default:
        onClickMonths()
        break
    }
  }

  return (
    <StyledTextClick onClick={onClick}>
      <StyledTextRed styles={{ isred: totalOperations === 0 }}>
        <Row justify="space-between">
          <Col span={18}>
            <Amount
              amount={convertNumberToMoney(totalAmount).replace('$', '')}
              centsSize={'10'}
              amountSize={'15'}
              currecySize={'10'}
            />
          </Col>
          <Col span={6}>
            <StyledRightOutlined
              styles={{ isred: totalOperations === 0 }}
              data-testid="icon"
            />
          </Col>
        </Row>
      </StyledTextRed>
    </StyledTextClick>
  )
}

export const renderTotalOperations = (
  _objt: string,
  row: QueryReportDetailSummary
): React.ReactElement => {
  const { totalOperations } = row
  return (
    <StyledTextRed styles={{ isred: totalOperations === 0 }}>
      {`${totalOperations} ops`}
    </StyledTextRed>
  )
}

export const renderMonth = (
  _objt: string,
  row: QueryReportDetailSummary
): React.ReactElement => {
  const { totalOperations, item, view } = row
  let dateFormat
  switch (view) {
    case 'month':
      dateFormat = getMonthDayFromEightDigits(item)
      break

    default:
      dateFormat = getMonthYearFromSixDigits(item)
      break
  }

  return (
    <StyledTextRed styles={{ isred: totalOperations === 0 }}>
      {dateFormat}
    </StyledTextRed>
  )
}

export const reportMonthColumns: TGColumnProp[] = [
  { dataIndex: 'item', title: 'Column1', render: renderMonth },
  {
    dataIndex: 'totalOperations',
    title: 'Column2',
    render: renderTotalOperations
  },
  {
    dataIndex: 'totalAmount',
    title: 'Column3',
    render: renderTotal
  }
]
