import * as XLSX from 'xlsx-js-style'
import moment from 'moment'
import constants from '../../data/constants'
import { getFromTitleDetail } from '../../containers/ReportContainerByMonths/utils'

const { exportExcelConfig } = constants
const { style, columnsByMonths, columnsByMonthsDays, styleDate, styleTitle } =
  exportExcelConfig['ReportSales']

interface DateRange {
  from: string
  to: string
}

export const configSizes = (
  ws: XLSX.WorkSheet,
  dateRange: DateRange
): XLSX.WorkSheet => {
  ws['!cols'] = !dateRange ? columnsByMonths : columnsByMonthsDays
  return ws
}

export const applyFormat = (
  cells: string[],
  ws: XLSX.WorkSheet
): XLSX.WorkSheet => {
  // deepcode ignore CallbackShouldReturn: <please specify a reason of ignoring this>
  cells.map((cell) => {
    ws[cell]['s'] = { ...style }
  })
  return ws
}

export const styleByMonth = (ws: XLSX.WorkSheet): XLSX.WorkSheet => {
  const cells = ['C6', 'E6', 'G6', 'I6', 'K6', 'M6', 'O6']
  ws = applyFormat(cells, ws)
  ws = {
    ...ws,
    ['N2']: {
      t: 's',
      v: `Fecha de creación: ${moment().format('D-MMM-YYYY')}`,
      s: { ...styleDate }
    }
  }
  return ws
}

export const styleByMonthDays = (ws: XLSX.WorkSheet): XLSX.WorkSheet => {
  const cells = ['A9', 'B9', 'C9', 'D9', 'E9', 'F9', 'G9', 'H9']
  ws = applyFormat(cells, ws)
  ws = {
    ...ws,
    ['H2']: {
      t: 's',
      v: `Fecha de creación: ${moment().format('D-MMM-YYYY')}`,
      s: { ...styleDate }
    }
  }
  return ws
}

export const formatDate = (from: string, to: string): string => {
  const fromFormated = from?.replaceAll('-', '')
  const toFormated = to?.replaceAll('-', '')

  return `${getFromTitleDetail(fromFormated, toFormated).toUpperCase()}`
}

export const printTitle = (
  ws: XLSX.WorkSheet,
  dateRange: DateRange
): XLSX.WorkSheet => {
  const title = !dateRange
    ? 'REPORTE DE VENTAS TOTALES MENSUALES'
    : `REPORTE DE VENTAS ${formatDate(dateRange.from, dateRange.to)}`
  ws = {
    ...ws,
    ['D2']: {
      t: 's',
      v: title,
      s: { ...styleTitle }
    }
  }
  return ws
}

export const styles = (
  ws: XLSX.WorkSheet,
  dateRange: DateRange
): XLSX.WorkSheet => {
  const cells = ['A1', 'A2', 'A3', 'A4', 'A6']
  ws = applyFormat(cells, ws)
  ws = !dateRange ? styleByMonth(ws) : styleByMonthDays(ws)
  return ws
}

export const downloadFile = (ws: XLSX.WorkSheet): void => {
  const wb = { Sheets: { pagina1: ws }, SheetNames: ['pagina1'] }
  XLSX.writeFile(wb, 'ReporteDeVentas.xlsx')
}

export const transformWS = (ws: XLSX.WorkSheet, dateRange: DateRange): void => {
  ws = configSizes(ws, dateRange)
  ws = styles(ws, dateRange)
  ws = printTitle(ws, dateRange)
  downloadFile(ws)
}

interface Api {
  content: any
  dateRange?: any
}

export const callApiExportExcel = ({ content, dateRange }: Api): void => {
  const ws = XLSX.utils.table_to_sheet(content(), { raw: true })
  transformWS(ws, dateRange)
}

export const callApiExportPDF = (): boolean => true
