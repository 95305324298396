import React, { useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useReactToPrint } from 'react-to-print'

// Components
import ReportContent from '../../components/ReportContent'
import ModalResult from '../../components/Shared/ModalResult'
import ModalResponse from '../../components/Shared/ModalResponse'
import CustomButton from '../../components/Storybook/customButton'

import { transformQueryReportToDataComponents } from './utils'
import { callApiExportExcel } from '../../utils/apis'
import { getParameter } from '../../utils/queryString'
import {
  GET_MONTHS,
  GET_TOTALOPERATIONS_REPORT
} from '../../graphql/queries/Reports'

import { deleteLocalStorageItem, isBrowser } from '../../utils/storage'
import { ReportByMonthsPDF } from '../../components/ReportByMonthsPDF'
import UserTypes from '../../types/UserTypes'
import { customEventGA } from '../../utils/googleAnalitycs'
import { useSelector } from 'react-redux'
import { callProfileRedux } from '../ProfileContainer'
import { navigate } from 'gatsby'
import { Utils } from '../../utils'

const ReportContainerByMonth: React.FC = () => {
  const [showNoResultsPopup, setShowNoResultsPopup] = useState(false)
  const [typeDownload, setTypeDownload] = useState<'PDF' | 'CSV' | undefined>(
    undefined
  )
  const [permission, setPermission] = useState<string | undefined>(undefined)
  const [typeModal, setTypeModal] = useState<'PDF' | 'CSV' | undefined>(
    undefined
  )
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)

  const { user_data } = useSelector(callProfileRedux)
  const componentRef = useRef(null)

  isBrowser() && window.localStorage.setItem('monthDaysFrom', '')
  isBrowser() && window.localStorage.setItem('monthDaysTo', '')

  const HandleShowPopup = () => {
    setShowNoResultsPopup(false)
  }
  const user: UserTypes.UserInformation = user_data.getProfile
  const storeId = getParameter('storeId')
  const userId = getParameter('userId')

  const store = user_data?.getProfile?.stores.find(
    ({ storeID }: { storeID: string }) => storeID === storeId
  )

  let tileRow: any = {
    rowBody: [],
    rowHeader: { parameter: '', value: '' }
  }
  let tableGrid: any = {
    data: [],
    columns: [],
    isHeader: false
  }

  const [
    refetch,
    {
      data: dataStoreMonths,
      loading: loadingStoreMonths,
      error: errorStoreMonths
    }
  ] = useLazyQuery(GET_MONTHS, {
    context: {
      clientName: 'api-instore'
    },
    fetchPolicy: 'cache-and-network'
  })

  const [
    refetchFile,
    {
      data: dataStoreMonthsFile,
      loading: loadingStoreMonthsFile,
      error: errorStoreMonthsFile
    }
  ] = useLazyQuery(GET_TOTALOPERATIONS_REPORT, {
    context: { clientName: 'api-instore' },
    fetchPolicy: 'cache-and-network'
  })

  const onClickDonwload = (): void => {
    const variables = { storeId, userId, permission }
    refetchFile({ variables })
    setTypeDownload(typeModal)
    setIsVisibleModal(false)
    setTypeModal(undefined)
  }

  const onClickDonwloadCSV = async (): Promise<void> => {
    callApiExportExcel({
      content: () => componentRef.current
    })
    setTypeDownload(undefined)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setTypeDownload(undefined)
  })

  const handleModal = (type: 'PDF' | 'CSV'): void => {
    customEventGA({
      eventCategory: 'Reports',
      eventAction: 'Download Total Sales Report ' + type,
      eventLabel: 'ReportContainerByMonths'
    })

    setTypeDownload(undefined)
    setIsVisibleModal(true)
    setTypeModal(type)
  }

  if (dataStoreMonths && !loadingStoreMonths && !errorStoreMonths && storeId) {
    if (
      dataStoreMonths.generalDetail.summarySales === null &&
      dataStoreMonths.generalDetail.detailSummary === null
    ) {
      navigate('/reports')
    } else {
      const { tileRow: tileRowTransformed, tableGrid: tableGridTransformed } =
        transformQueryReportToDataComponents(dataStoreMonths, 'months', storeId)
      tileRow = tileRowTransformed
      tableGrid = tableGridTransformed
    }
  }

  const simplePopupProps: any = {
    title: 'Error',
    text: 'No se ha ecnontrado el número de orden, verifica e intenta de nuevo',
    buttonText: 'ACEPTAR',
    buttonAction: HandleShowPopup
  }

  useEffect(() => {
    if (!user_data?.getProfile) return
    const actionID = Utils.permissions.getPermissionByModule(
      user_data.getProfile.permissions,
      'Reportes',
      'Reporte de ventas'
    )
    setPermission(actionID)
    navigate(
      actionID?.includes('02')
        ? `/reports/sales?storeId=${storeId}&userId=${userId}`
        : `/reports/sales?storeId=${storeId}`
    )
  }, [user_data])

  useEffect(() => {
    if (
      dataStoreMonthsFile &&
      !loadingStoreMonthsFile &&
      !errorStoreMonthsFile
    ) {
      setTimeout(() => {
        if (typeDownload === 'CSV') {
          onClickDonwloadCSV()
        }
        if (typeDownload === 'PDF') {
          handlePrint()
        }
      }, 10)
    }
  }, [typeDownload, dataStoreMonthsFile, loadingStoreMonthsFile])

  useEffect(() => {
    if (storeId) {
      const variables = { storeId, userId, permission }
      refetch({
        variables
      })
    }
  }, [storeId, userId])

  useEffect(() => {
    deleteLocalStorageItem('userTypeLabel')
  }, [])

  const modalContent = (
    <ModalResult
      key="cancel-edit-modal"
      status={'warning'}
      subTitle={`¿Deseas continuar con la descarga del archivo ${
        typeModal === 'PDF' ? typeModal : 'Excel'
      }?`}
      extra={
        <>
          <CustomButton
            id="success-sales-report-download-file"
            name="successSalesReportDownloadFile"
            dataTestId="data-testid-success-sales-report-download-file"
            label="Aceptar"
            className="save-button-sales-report-download-file"
            onClick={onClickDonwload}
          />
          <CustomButton
            id="cancel-sales-report-download-file"
            name="cancelSalesReportDownloadFile"
            dataTestId="data-testid-cancel-sales-report-download-file"
            label="Cancelar"
            className="cancel-button-sales-report-download-file"
            onClick={() => setIsVisibleModal(false)}
          />
        </>
      }
    />
  )

  return (
    <>
      <div style={{ display: 'none' }}>
        <ReportByMonthsPDF
          ref={componentRef}
          queryData={dataStoreMonthsFile}
          storeInformation={{
            storeID: storeId,
            partnerName:
              user?.name + ' ' + user?.familyName + ' ' + user?.middleName,
            storeName: store?.name
          }}
        />
      </div>
      <ReportContent
        tileRow={tileRow}
        tableGrid={tableGrid}
        loading={loadingStoreMonths}
        simplePopupProps={simplePopupProps}
        showNoResultsPopup={showNoResultsPopup}
        setShowNoResultsPopup={HandleShowPopup}
        view="months"
        onClickDonwloadPDF={() => handleModal('PDF')}
        onClickDonwloadCSV={() => handleModal('CSV')}
        disabledDownload={
          dataStoreMonths?.generalDetail.detailSummary === null ||
          dataStoreMonths?.generalDetail.detailSummary.length === 0 ||
          loadingStoreMonthsFile
        }
        storeID={storeId ? storeId : user.stores[0].storeID}
      />
      <ModalResponse
        isModalVisible={isVisibleModal}
        content={modalContent}
        onCancel={() => {
          setIsVisibleModal(false)
        }}
      />
    </>
  )
}

export default ReportContainerByMonth
